import whistleblowerMain from "../../assets/whistleblowerMain.png";
import wb1 from "../../assets/wb1.png";
import wb2 from "../../assets/wb2.png";
import wb3 from "../../assets/wb3.png";
import wb4 from "../../assets/wb4.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";import { server } from "../../config/server.js";
import { useNavigate } from "react-router-dom";import  WBSMenu from "./WBSMenu.js"
export default function Whistleblower() {

  const navigate = useNavigate();
  const [data, setData] = useState([]); 
  const [headerImg, setHeaderImg] = useState([]);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const getData = () => {
    fetch(`${server}/api/v1/cms/content/27/`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          setData(res.data[0] ? res.data[0] : {});
          setHeaderImg(res.data[0] ? res.data[0].
            content_header_link : '')
        } else {
          throw res
        }



      })


      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${whistleblowerMain})` }}
      ></div> */}
            <img class="w100" className="w100 mb-5"style={{ borderRadius: 20 }} src={headerImg}></img>
    <WBSMenu backButton={false}/>
      <div
        class="mw1000 mx-auto flex justify-content-center my-5 fwrap1200"
        style={{
          gap: 54,
        }}
      >
        <div class="" style={{width: '-webkit-fill-available'}}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb1}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Periksa Syarat Kelengkapan
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Periksa kelengkapan dan syarat pengaduan yang akan Anda buat
          </div>
        </div>
        <div class="" style={{width: '-webkit-fill-available'}}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb2}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Isi Formulir Pengaduan
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Klik "Buat Pengaduan" lalu isi formulir yang disediakan
          </div>
        </div>
        <div class="" style={{width: '-webkit-fill-available'}}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb3}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Simpan Info Akun Pelapor
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
            }}
            class="mt-2"
          >
            Simpan dengan baik Info akun untuk masuk ke halaman pelapor
          </div>
        </div>
        <div class="" style={{width: '-webkit-fill-available'}}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb4}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Pantau Proses Pengaduan
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
            }}
            class="mt-2"
          >
            Pantau seluruh proses pengaduan yang pernah Anda buat sebelumnya
          </div>
        </div>
      </div>
      <div class="pageTitle">{data.content_title ? data.content_title : 'Whistleblower'}</div>
      <div class="articleContainer pageText">
        <p>
          Sistem Whistleblowing PT. Kawasan BerikatNusantara (Persero) adalah
          mekanisme pelaporan perbuatan yang berindikasipelaporan/kecurangan
          atau tindakan korupsi yang terjadi di lingkungan PT.Kawasan Berikat
          Nusantara (Persero) dan merupakan salah satu cara palingefektif untuk
          mencegah dan memerangi praktek yang bertentangan dengan praktekGood
          Corporate Governance (GCG).
        </p>
        <br></br>
        <p>
          Unsur Pengaduan<br></br>
          Pengaduan akan mudah ditindaklanjuti apabila memenuhi beberapa unsur
          berikut :
        </p>

        <div class="flex">
          <div>
            <div class="mx-5">Apa</div>
            <div class="mx-5">Dimana</div>
            <div class="mx-5">Kapan</div>
            <div class="mx-5">Siapa</div>
            <div class="mx-5">Bagaimana</div>
          </div>
          <div>
            <div class="mx-5">
              : Perbuatan berindikasi pelanggaran yang diketahui.
            </div>
            <div class="mx-5">: Dimana perbuatan tersebut dilakukan.</div>
            <div class="mx-5">: Kapan perbuatan tersebut dilakukan.</div>
            <div class="mx-5">
              : Siapa saja yang terlibat dalam perbuatan tersebut.
            </div>
            <div class="mx-5">
              : Bagaimana perbuatan tersebutdilakukan (modus, cara, dsb).
            </div>
          </div>
        </div>
        <br></br>

        <p>
          Pelapor membuat pengaduan/pengungkapan dan mengirimkannya melalui
          sarana/media sebagai berikut :
        </p>

        <div class="flex">
          <div>
            <div class="mx-5">Telp.  </div>
            <div class="mx-5">E-mail  </div>
          </div>
          <div>
            <div class="mx-5">: (021) 4482-0909 ext. 1334</div>
            <div class="mx-5">: pengaduan@kbn.co.id</div>
          </div>
        </div>
        <br></br>
        <p>
          Kotakpengaduan yang diletakan di tempat strategis yaitu dilingkungan
          Kantor PusatPT. KBN (P), Kantor SBU Kawasan Cakung, Kantor SBU
          Pelayanan Logistik, KantorSBU Kawasan Priok dan Marunda.
        </p>
        <p>Perlindungan dan Hak Saksi Pelapor</p>
        <p>
          Sesuai dengan Pasal VII dalam Surat Keputusan Direksi PT.Kawasan
          Berikat Nusantara ( Persero ) No. 119/SKD/DU.DU54/06/2020 tentang
          Perubahan Atau Pedoman Sistem Pelaporan Whistlebowing System (WBS) di
          PT. Kawasan Berikat Nusantara (Persero ) menyebutkan bahwa seorang
          sanksi pelapor berhak :
        </p>
        <p>
          <ul>
            <li>
              Memperoleh perlindungan atas kerahasiaan identitas pribadi,
              jabatannya dan hak hak sebagai sebagai pegawai lainnya yang
              berkenan dengan kesaksiannya yang akan,sedang atau telah
              diberikannya.
            </li>
            <li>Memberikan keterangan tanpa tekanan dari pihak manapun.</li>
            <li>Bebas dari pertanyaan yang menjerat</li>
            <li>
              Mendapat informasi mengenai perkembangan kasus yang telah
              dilaporkan.
            </li>
            <li>
              Memperoleh penghargaan ( reward ) yang akan ditetapkan oleh
              Direksi
            </li>
          </ul>
        </p>
        <ViewCount count={data.views ? data.views : 0} />
      </div>
    </>
  );
}
